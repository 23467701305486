import React, { useState, useRef, useEffect } from 'react';
// Function to add highlight boxes
const addHighlightBox = (boundingBox, scaleX, scaleY) => {
  if (!boundingBox || boundingBox.length === 0) return null;
  const [x1, y1, x2, y2, x3, y3, x4, y4] = boundingBox;
  const left = Math.min(x1, x2, x3, x4) * scaleX;
  const top = Math.min(y1, y2, y3, y4) * scaleY;
  const width = (Math.max(x2, x3) - Math.min(x1, x4)) * scaleX;
  const height = (Math.max(y3, y4) - Math.min(y1, y2)) * scaleY;
  return (
    <div
      className="bounding-box"
      style={{
        position: 'absolute',
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`,
        border: '2px solid red',
        pointerEvents: 'none',
        backgroundColor: 'rgba(255, 255, 0, 0.3)',
        boxSizing: 'border-box',
        zIndex: 1000,  // Ensure it appears above the image
      }}
      key={`highlight-${x1}-${y1}`}
    />
  );
};
// Define the Magnifier component
const Magnifier = ({ base64Image, scale = 2, magnifierHeight = 150, magnifierWidth = 150, boundingBoxes }) => {
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [magnifierStyle, setMagnifierStyle] = useState({});
  const imgRef = useRef(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const updateImageDimensions = () => {
      if (imgRef.current) {
        setImageDimensions({
          width: imgRef.current.clientWidth,
          height: imgRef.current.clientHeight,
        });
      }
    };
    // Update dimensions on image load
    const imgElement = imgRef.current;
    if (imgElement) {
      imgElement.addEventListener('load', updateImageDimensions);
      updateImageDimensions(); // Call once on mount to get initial dimensions
    }
    return () => {
      if (imgElement) {
        imgElement.removeEventListener('load', updateImageDimensions);
      }
    };
  }, [base64Image]);
  // Mouse enter handler to show magnifier
  const handleMouseEnter = () => {
    setShowMagnifier(true);
  };
  // Mouse move handler to update magnifier style
  const handleMouseMove = (e) => {
    const img = imgRef.current;
    const { top, left, width, height } = img.getBoundingClientRect();
  
    // Calculate the cursor's x and y position relative to the image
    const x = e.clientX - left;
    const y = e.clientY - top;
  
    // Check if the cursor is within the image bounds
    if (x > 0 && y > 0 && x < width && y < height) {
      // Calculate the background position and styles for the magnifier
      const backgroundX = (x / width * img.naturalWidth * scale - magnifierWidth / 2) * -1;
      const backgroundY = (y / height * img.naturalHeight * scale - magnifierHeight / 2) * -1;
      
      // Place the magnifier below the cursor
      const magnifierX = e.clientX - left - magnifierWidth / 2; // Horizontal center of cursor
      const magnifierY = e.clientY - top + 10;  // 10 pixels below the cursor
  
      setMagnifierStyle({
        left: magnifierX,
        top: magnifierY,
        backgroundPosition: `${backgroundX}px ${backgroundY}px`,
        backgroundSize: `${img.naturalWidth * scale}px ${img.naturalHeight * scale}px`,
      });
    } else {
      setShowMagnifier(false);
    }
  };
  // Mouse leave handler to hide magnifier
  const handleMouseLeave = () => {
    setShowMagnifier(false);
  };
  return (
    <div
      className="image-magnifier-container"
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {base64Image && (
        <img
          src={`data:image/png;base64,${base64Image}`}
          ref={imgRef}
          alt="Document"
          style={{ objectFit: "contain", maxWidth: '100%', maxHeight: '100%', display: 'block' }}
        />
      )}
      {showMagnifier && base64Image && (
        <div
          className="magnifier"
          style={{
            ...magnifierStyle,
            position: 'absolute',
            pointerEvents: 'none',
            width: `${magnifierWidth}px`,
            height: `${magnifierHeight}px`,
            borderRadius: '50%',
            backgroundImage: `url(data:image/png;base64,${base64Image})`,
            backgroundRepeat: 'no-repeat',
            border: '3px solid #000',
            zIndex: 9999,
          }}
        />
      )}
      {/* Render bounding boxes */}
      {boundingBoxes && Object.keys(boundingBoxes).map((key) => {
        const box = boundingBoxes[key];
        const scaleX = imageDimensions.width / (imgRef.current?.naturalWidth || 1);
        const scaleY = imageDimensions.height / (imgRef.current?.naturalHeight || 1);
        // Check if the bounding box is an array of arrays or a single array
        if (Array.isArray(box[0])) {
          return box.map((nestedBox, index) =>
            addHighlightBox(nestedBox, scaleX, scaleY, `${key}-${index}`)
          );
        } else {
          return addHighlightBox(box, scaleX, scaleY, key);
        }
      })}
    </div>
  );
};
export default Magnifier;