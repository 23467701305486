import React from 'react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel } from '@fortawesome/free-solid-svg-icons';  // Import from the solid icon package
import './NavBar.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import profile from '../assets/profileImage.png'
import { useState } from 'react';
import img from '../assets/hotellogo.png'
import InvoiceForm from '../InvoiceForm/InvoiceForm';

const NavBar = () => {
  const location = useLocation();
  const bookingTableFolder = location.pathname.startsWith('/bookingTable');
  const [activeLink, setActiveLink] = useState('');

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };


  return (
    <nav className="navbar">
      <div className="navbar-left">
      <img src={img} alt="logo" className="hotellogo" />
        
        <div className="navbar-center">
        {bookingTableFolder ? (
            <>
              <Link to="#home"
              className={activeLink === '#home' ? 'active-link' : ''}
              onClick={() => handleLinkClick('#home')}>Home</Link>
              <Link to="#bookings">Bookings</Link>
            </>
          ) : (
            <>
              <Link to="#home"
              className={activeLink === '#home' ? 'active-link' : ''}
              onClick={() => handleLinkClick('#home')}>Home</Link>
              <Link to="#bookings">Bookings</Link>
            </>
          )}
      </div>
      </div>
      <div className="navbar-right">



      {bookingTableFolder ? (
            <>
              <img src={profile} alt='Image not found' className='profileImage'/>
              <Link to='#completeProfile' ><button className="complete-profile-button">Complete Profile</button></Link>
            </>
          ) : (
            <>
                 <img src={profile} alt='Image not found' className='profileImage'/>
                 <Link to='#completeProfile' ><button className="complete-profile-button">Complete Profile</button></Link>
            </>
          )}

       
       
      </div>
    </nav>
  );
};

export default NavBar;
