import React, { useState, useEffect } from "react";
import "./landing.scss";
import HeaderNew from "../shared/Header";
import BackImage from "../../src/assets/LandingBackground.png";
const FinalLandingPage = () => {
  return (
    <div className="LandingPageHome">
      <div className="HeaderContainer">
        <HeaderNew />
      </div>
      <div className="MainContainer">
        <div className="BackgroundImage">
          <img src={BackImage} alt="BackImage" />
        </div>
        <div className="ContentLeft">
          <h1>
            Your corporate customers are <br /> waiting for your invoices
          </h1>
          <p className="HeroDescription">
            Loram ipsum need line for the process
          </p>
        </div>
        <div className="ContentRight"></div>
      </div>
    </div>
  );
};

export default FinalLandingPage;
