import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import NavBar from "./LandingPage/NavBar";
import CheckScorePage from "./LandingPage/CheckScorePage";
import SignUp from "./RegistrationPage/SignUp";
import Verify from "./RegistrationPage/Verify";
import { FormDataProvider } from "./FormData/FormData";
import { GstinProvider } from "./FormData/GstinProvider";
import LogIn from "./LoginPage/LogIn";
import LoginVerify from "./LoginPage/LoginVerify";
import BookingDetailsTable from "./HotelTableData/BookingDetailsTable";
import InvoiceForm from "./InvoiceForm/InvoiceForm";
import HotelDetails from "./RegistrationPage/HotelDetailsPage";
import ClientSection from "./clientsection/ClientSection";
import HotelContactManagement from "./scenes/HotelContactManagement";
import FinalLandingPage from "./NewResponsive_LandingPage/LandingPage";

const AppContent = () => {
  const location = useLocation();
  const hideNavbarPaths = [
    "/",
    "/verify",
    "/signup",
    "/contactinfo",
    "/confirminfo",
    "/accessdashboard",
    "/backaccountinfo",
    "/signin",
    "/loginverify",
    "/confirminfo2",
    "/hoteldetails",
    "/clients",
    "/hotel_bookings",
  ];

  return (
    <div>
      {!hideNavbarPaths.includes(location.pathname) && <NavBar />}
      <Routes>
        <Route path="/" element={<FinalLandingPage />} />

        <Route path="/check-score" element={<CheckScorePage />} />
        <Route path="/hoteldetails" element={<HotelDetails />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verify" element={<Verify />} />
        {/* <Route path="/contactinfo" element={<ContactInfo />} />
        <Route path="/confirminfo" element={<ConfirmYourInformation/>} />
        <Route path="/confirminfo2" element={<ConfirmYourInformation2/>} />
        <Route path="/backaccountinfo" element={<BankInfo/>}/>
        <Route path="/accessdashboard" element={<AccessDashboard/>}/> */}
        {/* <Route path="/new" element={<New/>}/> */}
        <Route path="/signin" element={<LogIn />} />
        <Route path="/loginverify" element={<LoginVerify />} />
        <Route path="/bookingTable" element={<BookingDetailsTable />} />
        <Route path="/invoiceform" element={<InvoiceForm />} />
        <Route path="/clients" element={<ClientSection />} />
        <Route path="/hotel_bookings" element={<HotelContactManagement />} />
      </Routes>
    </div>
  );
};

const App = () => (
  <FormDataProvider>
    <GstinProvider>
      <Router>
        <AppContent />
      </Router>
    </GstinProvider>
  </FormDataProvider>
);

export default App;
