import React, { useState, useEffect } from "react";
import "./InvoiceForm.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Magnifier from "./Magnifying";
import axios from 'axios';


const InvoiceForm = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [boundingBoxes, setBoundingBoxes] = useState({});
  const [hoveredBoundingBox, setHoveredBoundingBox] = useState([]);
  const [fileData, setFileData] = useState(null);
  const navigate = useNavigate();
  const [s3url, setS3Url] = useState();

  const location = useLocation();
  // const rowData = location.state?.rowData;

  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handleFileChange({ target: { files } });
    }
  };


   const [values, setValues] = useState({
    Invoice_Number: location.state?.invoiceNumber || "",
    Invoice_Date: location.state?.invoiceDate || "",
    Hotel_Gstin: location.state?.parsedhotelGstin || "",
    Total_Amount: location.state?.totalAmount || "",
    Total_Tax_Amount: location.state?.totalTaxAmount || "",
  });


  const [image, setImage] = useState(null);
  const [isImageReady, setIsImageReady] = useState(false);
  const [parsedData, setParsedData] = useState(null);
  const [buttonState, setButtonState] = useState("Autofill");
  const [autoFillRequested, setAutoFillRequested] = useState(false);

  
  useEffect(() => {
    if (autoFillRequested && parsedData) {
      handleAutofillData();
      setButtonState("Filled");
      setAutoFillRequested(false);
    }
  }, [parsedData, autoFillRequested]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      setFileData(file)
      try {
        setUploading(true);
        setError(null);
        const response = await fetch("http://127.0.0.1:7000/api/parseimage", {
          method: "POST",
          body: formData,
        });
        if (!response.ok) throw new Error("Failed to fetch image");
        const imageData = await response.json();
        setImage(imageData.image);
        setIsImageReady(true);
        parseData(imageData);
      } catch (error) {
        setError("An error occurred during file processing.");
        console.error("Error processing file: ", error);
      } finally {
        setUploading(false);
      }
    }
  };
  const parseData = async (image) => {
    try {
      const parseResponse = await fetch("http://127.0.0.1:7000/api/parsedata", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ imageId: image }),
      });
      if (!parseResponse.ok) throw new Error("Failed to parse data");
      const data = await parseResponse.json();
      console.log("Parsed data fetched:", data);
      setParsedData(data);
      setValues((prevValues) => ({
        ...prevValues,
        ...data,
      }));
    } catch (error) {
      console.error("Error parsing data: ", error);
      setParsedData(null);
      setButtonState("Autofill");
    }
  };
  const handleAutofillClick = () => {
    setAutoFillRequested(true);
    setButtonState("Processing...");
  };
  const handleAutofillData = () => {
    if (parsedData?.final_data) {
      const valueData = {};
      const boundingBoxData = {};
      Object.keys(parsedData.final_data).forEach((key) => {
        const { value, bounding_box, bounding_boxes } = parsedData.final_data[key];
        valueData[key] = value;
        boundingBoxData[key] = bounding_boxes || (bounding_box ? [bounding_box] : []);
      });
      setValues(valueData);
      setBoundingBoxes(boundingBoxData);
    }
  };
  const handleColumnHover = (columnName, boundingBoxKey) => {
    setHoveredBoundingBox(boundingBoxes[boundingBoxKey] || []);
  };
  const handleColumnLeave = () => {
    setHoveredBoundingBox([]);
  };
  const columns = {
    Invoice_Number: "Invoice Number",
    Invoice_Date: "Invoice Date",
    Hotel_Gstin: "Hotel GSTIN",
    Total_Amount: "Total Amount",
    Total_Tax_Amount: "Total Tax Amount",
  };



  const handleConfirmInvoice = async (e) => {
    e.preventDefault();
  
    try {
      let fileUrl = ""; // Initialize fileUrl outside the if block
  
      if (fileData) {
        const formData = new FormData();
        formData.append('file', fileData);
  
        const uploadResponse = await axios.post('http://127.0.0.1:7000/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        if (uploadResponse.status === 200) {
          console.log('File uploaded successfully:', uploadResponse.data.message);
  
          // Fetch the file URL from the response and store it in state
          fileUrl = uploadResponse.data.s3_url; // Assign fileUrl here
          console.log("fileUrl", fileUrl);
  
          setS3Url(fileUrl); // Store the file URL in the state
        } else {
          console.error('Error uploading file:', uploadResponse.data.error);
        }
      }
  
      const updatePayload = {
        BookingDetails: location.state?.bookingDetails,
        ParsedData: [{'finalData' : values},{'boundingBoxes':boundingBoxes},{'s3url': fileUrl}], // Use the fileUrl here
      };
  
      const updateResponse = await axios.post('http://127.0.0.1:7000/api/bookings/update', updatePayload);
  
      if (updateResponse.status === 200) {
        navigate('/bookingTable');
      } else {
        console.error('Error updating booking:', updateResponse.data.error);
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };
  

  return (
    <div className="invoiceform-container">
      <div className="invoiceform-main-grid">
        <div className="invoiceform-left-section">
          <h4 className="invoiceform-booking-header">
          Booking Details &gt; {location.state?.bookingDetails}
          </h4>
          <p className="invoiceform-booking-status">
            <strong>Booking Details:</strong>
            <span className="invoiceform-status-pending">Pending</span>
          </p>
          <div className="invoiceform-details-grid">
          <div className="invoiceform-detail-box">
              <h6 className="invoiceform-head">Booking Details</h6>
              <p className="invoiceform-row-details">
                <strong>Customer Name:</strong> {location.state?.customerDetails}
              </p>
              <p className="invoiceform-row-details">
                <strong>Contact Details:</strong> {location.state?.phoneNumber}
              </p>
              <p className="invoiceform-row-details">
                <strong>Organization Name:</strong> {location.state?.company_name}
              </p>
              <p className="invoiceform-row-details">
                <strong>Organization GSTIN:</strong> {location.state?.gstinDetails}
              </p>
            </div>
            <div className="invoiceform-detail-box">
              <div className="invoiceform-detail-header">
                <h6 className="invoiceform-head">Invoice Details</h6>
                <button className="invoiceform-autofill-btn" onClick={handleAutofillClick}>
                  {buttonState}
                </button>
              </div>
              <table className="invoiceform-details-table">
                <tbody>
                  {Object.entries(columns).map(([key, label]) => (
                    <tr
                      key={key}
                      className="invoiceform-row-details"
                      onMouseEnter={() => handleColumnHover(label, key)}
                      onMouseLeave={handleColumnLeave}
                    >
                      <td>
                        <label>{label}:</label>
                      </td>
                      <td>
                        <input
                          className="invoiceform-input"
                          placeholder={`Enter ${label}`}
                          name={key}
                          value={values[key] || ""}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <table className="invoiceform-table">
            <thead>
              <tr>
                <th>HSN Code</th>
                <th>Amount</th>
                <th>Rate</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>12345 (Bangalore)</td>
                <td>₹2900.00</td>
                <td>12%</td>
                <td>6%</td>
                <td>6%</td>
                <td>₹3248.00</td>
              </tr>
              <tr>
                <td>996332 (Room Services and Food GST not Claimable)</td>
                <td>₹25.00</td>
                <td>6%</td>
                <td>3%</td>
                <td>3%</td>
                <td>₹26.50</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div className="invoiceform-total">
            <div className="invoiceform-actions">
              <span>Grand Total: ₹{values.Total_Amount || "0.00"}</span>
              <div>
                <Link to='/bookingTable' ><button className="invoiceform-btn-cancel">Cancel</button></Link>
              </div>
              <div>
                <button className="invoiceform-btn-confirm" onClick={handleConfirmInvoice}>
                  Confirm Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="invoiceform-right-section">
          <div
            className="invoiceform-upload-box"
            onClick={() => document.getElementById("fileUpload").click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            <div>
              {uploading ? (
                <div className="loading-spinner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ margin: "auto", display: "block" }}
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      fill="none"
                      stroke="#007CC2"
                      strokeWidth="10"
                      r="35"
                      strokeDasharray="164.93361431346415 56.97787143782138"
                    >
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        repeatCount="indefinite"
                        dur="1s"
                        keyTimes="0;1"
                        values="0 50 50;360 50 50"
                      ></animateTransform>
                    </circle>
                  </svg>
                  <p>Processing your invoice...</p>
                </div>
              ) : isImageReady ? (
                <Magnifier
                  base64Image={image}
                  scale={1}
                  magnifierHeight={150}
                  magnifierWidth={150}
                  boundingBoxes={{ hovered: hoveredBoundingBox }}
                />
              ) : (
                <div className="invoice-msg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      d="M21.375 13.5V19.5C21.375 19.7984 21.2565 20.0845 21.0455 20.2955C20.8345 20.5065 20.5484 20.625 20.25 20.625H3.75C3.45163 20.625 3.16548 20.5065 2.9545 20.2955C2.74353 20.0845 2.625 19.7984 2.625 19.5V13.5C2.625 13.2017 2.74353 12.9155 2.9545 12.7045C3.16548 12.4936 3.45163 12.375 3.75 12.375C4.04837 12.375 4.33452 12.4936 4.5455 12.7045C4.75647 12.9155 4.875 13.2017 4.875 13.5V18.375H19.125V13.5C19.125 13.2017 19.2435 12.9155 19.4545 12.7045C19.6655 12.4936 19.9516 12.375 20.25 12.375C20.5484 12.375 20.8345 12.4936 21.0455 12.7045C21.2565 12.9155 21.375 13.2017 21.375 13.5ZM9.04594 7.54597L10.875 5.71878V13.5C10.875 13.7984 10.9935 14.0845 11.2045 14.2955C11.4155 14.5065 11.7016 14.625 12 14.625C12.2984 14.625 12.5845 14.5065 12.7955 14.2955C13.0065 14.0845 13.125 13.7984 13.125 13.5V5.71878L14.9541 7.54878C15.0587 7.65343 15.1829 7.73644 15.3197 7.79307C15.4564 7.84971 15.6029 7.87886 15.7509 7.87886C15.8989 7.87886 16.0455 7.84971 16.1822 7.79307C16.3189 7.73644 16.4432 7.65343 16.5478 7.54878C16.6525 7.44414 16.7355 7.3199 16.7921 7.18317C16.8487 7.04645 16.8779 6.8999 16.8779 6.75191C16.8779 6.60391 16.8487 6.45737 16.7921 6.32064C16.7355 6.18391 16.6525 6.05968 16.5478 5.95503L12.7978 2.20503C12.6933 2.10015 12.5691 2.01694 12.4324 1.96016C12.2956 1.90337 12.149 1.87415 12.0009 1.87415C11.8529 1.87415 11.7063 1.90337 11.5695 1.96016C11.4328 2.01694 11.3086 2.10015 11.2041 2.20503L7.45406 5.95503C7.34942 6.05968 7.2664 6.18391 7.20977 6.32064C7.15314 6.45737 7.12399 6.60391 7.12399 6.75191C7.12399 7.05079 7.24272 7.33744 7.45406 7.54878C7.66541 7.76013 7.95205 7.87886 8.25094 7.87886C8.54982 7.87886 8.83647 7.76013 9.04781 7.54878L9.04594 7.54597Z"
                      fill="#007CC2"
                    />
                  </svg>
                  <p>
                    Drag 'n' drop your hotel invoice here,
                    <br />
                    or click to select hotel invoice file
                  </p>
                </div>
              )}
            </div>
            <input
              id="fileUpload"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default InvoiceForm;