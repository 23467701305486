import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "./HotelContactManagement.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import Loader from "../../shared/Loader";
import moment from "moment";
import {
  MailOutlined,
  PhoneOutlined,
  EditOutlined,
  FileExclamationOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Badge, Button, Drawer, Modal, Tag, Tooltip } from "antd";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Handlebars from "handlebars";

import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import HotelDataUpdate from "../../shared/HotelData";
import { param } from "jquery";
import SearchGSTIN from "./components/SearchGSTIN";
import HotelGSTINMapping from "./components/HotelGSTINMapping";
import {
  API_ENDPOINT_FETCH_TEMPLATE_INFO,
  API_ENDPOINT_HOTEL_TRIGGER_MAIL,
  API_ENDPOINT_HOTEL_VIEW_LIST,
} from "./hotelcontact.constants";

const colorOfEmailEvent = {
  processed: "green",
  delivered: "green",
  open: "green",
  click: "green",
  bounce: "#e74c3c",
  dropped: "#e74c3c",
  spammed: "#e74c3c",
  deffered: "#e74c3c",
};

export default function HotelContactManagement(props) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showModal, setShowModal] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const EVENT_ICON_MAP = {
    processed: (color) => (
      <CheckOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    delivered: (color) => (
      <DoneAllOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    open: (color) => (
      <DraftsOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    click: (color) => (
      <AdsClickOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    acknowledge: (color) => (
      <AssignmentTurnedInIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
          marginRight: 28,
        }}
      />
    ),

    UNSUBSCRIBE: (color) => (
      <UnsubscribeOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    SPAMMED: (color) => (
      <PrivacyTipOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    bounce: (color) => (
      <CancelOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),

    dropped: (color) => (
      <MarkunreadMailboxOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),

    DEFFERED: (color) => (
      <ScheduleSendOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
  };

  const fetchTemplateInfo = async () => {
    const response = await axios.get(
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-ed0fbc920d5149569f317cb6ccbfacb2"
    );
    if (response) {
      setTemplateData(response.data.html_content);
    }
  };

  const handleRenderEmailEvents = (eventData) => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginRight: 6,
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Tooltip placement="bottom" title={eventData?.event}>
            {EVENT_ICON_MAP[eventData?.event] ? (
              <Tag icon={EVENT_ICON_MAP[eventData?.event]("blue")}>
                {eventData?.event}
              </Tag>
            ) : (
              <Tag color="red">No Event</Tag>
            )}
          </Tooltip>
        </div>
      </div>
    );
  };

  const handleAddGSTIN = (data) => {
    setSelectedHotel(data);
    setOpenDrawer(true);
  };

  const gridOptions = {
    rowModelType: "serverSide",
    serverSideStoreType: "partial", // For pagination
    cacheBlockSize: 100, // Number of rows per block (pagination size)
    paginationPageSize: 100, // Page size
  };

  const handleTriggerEmail = async (hotelInfo) => {
    const response = await axios.post(
      API_ENDPOINT_HOTEL_TRIGGER_MAIL + hotelInfo._id,
      { body: hotelInfo }
    );
  };
  const columnDefs = [
    {
      field: "sno",
      headerName: "SR No",
      cellRenderer: (params) => {
        return parseInt(params.node.id) + 1;
      },
    },
    {
      field: "template",
      headerName: "Template",
      width: 300,
      cellRenderer: (params) => {
        return (
          <Tag
            icon={<FileExclamationOutlined />}
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedBooking(params.data);
              setShowModal("TEMPLATE_VIEW");
            }}
          >
            Followup Template
          </Tag>
        );
      },
    },
    {
      field: "hotel_contact",
      headerName: "Hotel Contact",
      width: 200,
      filter: "agNumberColumnFilter",

      cellRenderer: (params) => {
        return (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              setSelectedBooking(params.data);
              setShowModal("ADD_HOTEL");
            }}
          >
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: "50%",
                backgroundColor: "black",
                alignItems: "center",
                display: "flex",
                color: "white",
                fontSize: 12,
                justifyContent: "center",
                marginRight: 4,
              }}
            >
              {params.data?.contact_details?.length}
            </div>

            <EditOutlined style={{ cursor: "pointer" }} />
          </div>
        );
      },
      valueGetter: (params) => {
        return params.data?.contact_details?.length || 0;
      },
    },
    {
      field: "name",
      headerName: "Hotel Name",
      width: 300,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "address",
      headerName: "Hotel Address",
      width: 300,
    },
    {
      field: "pending_invoice_count",
      headerName: "Pending Invoices",
      width: 300,
    },
    {
      field: "mapped_gstin",
      headerName: "Hotel GSTIN",
      filter: "agTextColumnFilter",

      width: 240,
      cellRenderer: (params) => {
        return params.data?.mapped_gstin ? (
          params.data?.mapped_gstin
        ) : (
          <Button
            size="small"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleAddGSTIN(params.data)}
          >
            Add GSTIN
          </Button>
        );
      },
    },
    {
      field: "email_sent_status",
      headerName: "Email Sent Status",
      cellRenderer: (params) => {
        return params.data?.last_email ? (
          <Tag color="green">Yes</Tag>
        ) : (
          <Tag color="red">No</Tag>
        );
      },
    },
    {
      field: "email_logs",
      headerName: "Email Logs",
      columnGroupShow: "close",
      formatType: "CUSTOM_RENDER",
      filter: "agSetColumnFilter",
      width: 300,

      cellRenderer: (params) => {
        return handleRenderEmailEvents(params.data?.last_email_event);
      },
    },
    {
      field: "last_mail_sent",
      headerName: "Last Email Sent",
      width: 300,
      cellRenderer: (params) => {
        return (
          params.data?.last_email?.timestamp &&
          moment(params.data?.last_email?.timestamp).format(
            "DD-MM-YYYY HH:MM A"
          )
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            type="primary"
            onClick={() => handleTriggerEmail(params.data)}
          >
            Send Email
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    // fetchHotelList();
    fetchTemplateInfo();
  }, []);
  const fetchHotelList = async () => {
    try {
      const response = await axios.get(API_ENDPOINT_HOTEL_VIEW_LIST);

      setRowData(response.data.data);
    } catch (error) {
      console.error("Error fetching hotels:", error);
    }
    setLoading(false);
  };

  const datasource = {
    getRows: function (params) {
      // Use the fetched data to update the grid
      fetch(API_ENDPOINT_HOTEL_VIEW_LIST, {
        method: "POST",
        body: JSON.stringify({
          ...params.request,
          ...props.serverPayload,
        }),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((httpResponse) => httpResponse.json())
        .then((response) => {
          params.success({
            rowData: response.data.rows,
            rowCount: response.data.lastRow,
            ...(response.data.pivotResultFields &&
            response.data.pivotResultFields.length > 0
              ? { pivotResultFields: response.data.pivotResultFields }
              : {}),
          });

          // handleColumnRowGroupChanged(params);
        })
        .catch((error) => {
          console.error(error);
          params.fail();
        });
    },
  };

  const onGridReady = (params) => {
    setGridApi(params.api);

    // Set the server-side datasource here
    const datasource = {
      getRows: (params) => {
        const request = {
          startRow: params.request.startRow,
          endRow: params.request.endRow,
          sortModel: params.request.sortModel,
          filterModel: params.request.filterModel,
        };
        console.log("Payload:", request);

        fetch(API_ENDPOINT_HOTEL_VIEW_LIST, {
          method: "POST",
          body: JSON.stringify(request),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Data is:", data);
            params.success({ rowData: data.data });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            params.fail();
          });
      },
    };

    // Set the server-side datasource
    params.api.setGridOption("serverSideDatasource", datasource);
  };

  const renderTemplate = (htmlContent, data = {}) => {
    const templateCompiler = Handlebars.compile(htmlContent);
    const renderedContent = templateCompiler({ hotel_name: data?.name });

    return renderedContent;
  };

  const gridRef = useRef();

  return (
    <div className="HotelContactManagement">
      <div className="MainContainer">
        <div className="ag-theme-alpine" style={{ height: "100%" }}>
          <AgGridReact
            rowModelType="serverSide" // Important: Enables server-side row model
            cacheBlockSize={100} // Optional: number of rows to load at once
            paginationPageSize={100} // Optional: set page size
            defaultColDef={{ resizable: true, flex: 1 }}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            ref={gridRef}
          />
        </div>
      </div>
      <Modal
        title={"Update Hotel Contact"}
        open={showModal !== ""}
        onCancel={() => setShowModal("")}
        footer={false}
        width={showModal === "TEMPLATE_VIEW" ? 800 : 600}
      >
        {showModal === "TEMPLATE_VIEW" ? (
          <div
            style={{ height: "70vh", overflowY: "scroll" }}
            dangerouslySetInnerHTML={{
              __html: renderTemplate(templateData || "", selectedBooking),
            }}
          ></div>
        ) : (
          <HotelDataUpdate
            bookingData={selectedBooking}
            onSuccess={() => {
              setShowModal("");
              fetchHotelList();
              // fetchBookingData();
            }}
          />
        )}
      </Modal>

      <Drawer
        title="Map Your GSTIN"
        placement={"right"}
        closable={false}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        key={"right"}
      >
        <HotelGSTINMapping
          hotelData={selectedHotel}
          onSuccess={() => {
            setOpenDrawer(false);
          }}
        />
      </Drawer>
    </div>
  );
}
